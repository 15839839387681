import cx from 'classnames';
import { Button, Space } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { GridContextProvider, GridDropZone, GridItem, swap, move } from 'react-grid-dnd';

import { THEME } from '../../../config/commons';
import { Store } from '../../../state/store.interfaces';
import { getTitlePhaseType } from '../../../helpers/header.helpers';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import { LrvModal } from '../../../common/components/LrvModal/LrvModal';

import DataItemDetail from './DataItemDetail';
import { AnalysisDetailCustom } from './interfaces';
import styles from './DetailCustomizable.module.scss';
import { getKeysAnalysisCustom, getLabelForKey, getValueForKey } from './helper';
import { saveDetailCustomizable, setShowDetailCustomizableModal, updateDetailCustomizable } from './detailCustomizableSlice';

const maxItemsToShow = 12;
interface Props {
  theme?: 'dark' | 'light';
}

export default function DetailCustomizable (props: Props) {
  const { theme } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const isLightTheme = theme === THEME.LIGHT;

  const { company } = useSelector((state: Store) => state.header);
  const { analysis: analysisOriginal, user } = useSelector((state: Store) => state.detailAnalysis);
  const { _id: detailCustomizableId, showDetailCustomizableModal, fields } = useSelector((state: Store) => state.detailCustomizable);

  const analysisCompanyId = analysisOriginal.companyId;
  const analysisPhaseType = analysisOriginal.phaseType;
  const [allData, setAllData] = useState<{ keysSelected: AnalysisDetailCustom[], otherKeys: AnalysisDetailCustom[] }>({ keysSelected: [], otherKeys: [] });

  useEffect(() => {
    const { keysSelected, otherKeys } = getKeysAnalysisCustom({ phaseType: analysisPhaseType, fields });
    setAllData({ keysSelected, otherKeys });
  }, [analysisPhaseType, fields]);

  const onChange = (sourceId: 'keysSelected' | 'otherKeys', sourceIndex: number, targetIndex: number, targetId?: 'keysSelected' | 'otherKeys') => {
    if (targetId === 'keysSelected' && allData.keysSelected.length >= maxItemsToShow) {
      return;
    }

    if (targetId) {
      const result = move(
        allData[sourceId],
        allData[targetId],
        sourceIndex,
        targetIndex
      );
      return setAllData({
        ...allData,
        [sourceId]: result[0],
        [targetId]: result[1]
      });
    }

    const result = swap(allData[sourceId], sourceIndex, targetIndex);
    return setAllData({
      ...allData,
      [sourceId]: result
    });
  };

  const isValidDetailCustomizable = () => {
    return allData.keysSelected.length === maxItemsToShow;
  };

  const onSaveDetailCustomizable = () => {
    const fields = allData.keysSelected;

    if (detailCustomizableId) {
      dispatch(updateDetailCustomizable({ _id: detailCustomizableId, fields }));
      dispatch(setShowDetailCustomizableModal(false));
      return;
    }

    dispatch(saveDetailCustomizable({ companyId: analysisCompanyId, phaseType: analysisPhaseType, fields }));
    dispatch(setShowDetailCustomizableModal(false));
  };

  const getGridItemClass = (index: number) => {
    if (![0, 1, 2, 6, 7, 8].includes(index)) {
      return '';
    }

    return isLightTheme ? styles.oddLight : styles.oddDark;
  };

  const getHoverGridItemClass = (index: number) => {
    switch (index) {
      case 0:
        return styles.hoverItem0;

      case 2:
        return styles.hoverItem2;

      case 9:
        return styles.hoverItem9;

      case 11:
        return styles.hoverItem11;
    }
  };

  const getBorderRadiusClass = (index: number) => {
    switch (index) {
      case 0:
        return styles.borderRadiusTopLeft;

      case 2:
        return styles.borderRadiusTopRight;

      case 9:
        return styles.borderRadiusBottomLeft;

      case 11:
        return styles.borderRadiusBottomRight;

      default:
        return '';
    }
  };

  const getWidthClass = (index: number) => {
    switch (index) {
      case 2:
      case 5:
      case 8:
      case 11:
        return styles.width;

      default:
        return '';
    }
  };

  const getHeightClass = (index: number) => {
    switch (index) {
      case 9:
      case 10:
      case 11:
        return styles.height;

      default:
        return '';
    }
  };

  return (
    <LrvModal
      open={showDetailCustomizableModal}
      title={t('analysisDetailCustom.title')}
      theme={theme}
      cancelText={undefined}
      className={styles.detailCustomizableModal}
      onCancel={() => dispatch(setShowDetailCustomizableModal(false))}
      footer={[
        <Button
          type='primary'
          className={styles.button}
          disabled={!isValidDetailCustomizable()}
          onClick={onSaveDetailCustomizable}
        >
          {t('analysisDetailCustom.save')}
        </Button>
      ]}
    >
      <div className={styles.container}>
        <LrvText
          className={styles.settings}
          theme={theme}
          text={'* ' + t('analysisDetailCustom.settings', { phaseType: getTitlePhaseType(analysisPhaseType) })}
        />

        <GridContextProvider
          onChange={(sourceId: string, sourceIndex: number, targetIndex: number, targetId?: string) => {
            onChange(sourceId as 'keysSelected' | 'otherKeys', sourceIndex, targetIndex, targetId as 'keysSelected' | 'otherKeys');
          }}
        >
          <Space className={styles.row} direction='vertical'>
            <div>
              <div className={styles.label}>
                <LrvText
                  className={styles.description}
                  theme={theme}
                  text={t('analysisDetailCustom.description')}
                />
              </div>

              <GridDropZone
                className={cx(styles.dropzone, styles.otherKeys, isLightTheme ? styles.otherKeysLight : styles.otherKeysDark)}
                id='otherKeys'
                boxesPerRow={2}
                rowHeight={40}
                style={{
                  height: ((allData.otherKeys.length + 1) * 20)
                }}
              >
                {allData.otherKeys.map(item => (
                  <GridItem key={item}>
                    <div className={cx(styles.gridItem, styles.gridItemOtherKeys)}>
                      <div className={cx(styles.gridItemContent, styles.keys)}>
                        <LrvText
                          text={getLabelForKey({ analysis: analysisOriginal, field: item })}
                          theme={theme}
                        />
                      </div>
                    </div>
                  </GridItem>
                ))}
              </GridDropZone>
            </div>

            <div>
              <GridDropZone
                className={cx(styles.dropzone, styles.keysSelected)}
                id='keysSelected'
                boxesPerRow={3}
                rowHeight={90}
              >
                {allData.keysSelected.map((item, index) => (
                  <GridItem
                    key={item}
                    className={cx(styles.grid, getGridItemClass(index), getBorderRadiusClass(index), getWidthClass(index), getHeightClass(index))}
                  >
                    <div className={cx(styles.gridItem, getHoverGridItemClass(index))}>
                      <div className={styles.gridItemContent}>
                        <DataItemDetail
                          label={getLabelForKey({ analysis: analysisOriginal, field: item })}
                          value={getValueForKey({ analysis: analysisOriginal, field: item, user, daysToInitialStage: company.daysToInitialStage })}
                          theme={theme}
                        />
                      </div>
                    </div>
                  </GridItem>
                ))}
              </GridDropZone>
            </div>
          </Space>
        </GridContextProvider>
      </div>
    </LrvModal>
  );
}
