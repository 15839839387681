import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { axiosClient as axios } from '../../../utils/axios_instance';
import { ANALYSIS_DETAIL_CUSTOMIZABLE_URL } from '../../../config/config.api';

import { DetailCustomizableStatus, DetailCustomizablePayload, AnalysisDetailCustom } from './interfaces';

const initialState: DetailCustomizableStatus = {
  _id: '',
  companyId: '',
  fields: [],
  phaseType: '',
  showDetailCustomizableModal: false,
  isLoading: false,
};

export const detailCustomizableSlice = createSlice({
  name: 'detailCustomizable',
  initialState,
  reducers: {
    setDetailCustomizable: (state: DetailCustomizableStatus, action: PayloadAction<DetailCustomizablePayload>) => {
      state._id = action.payload._id;
      state.companyId = action.payload.companyId;
      state.fields = action.payload.fields;
      state.phaseType = action.payload.phaseType;
    },

    resetDetailCustomizable: (state: DetailCustomizableStatus) => {
      state._id = '';
      state.companyId = '';
      state.fields = [];
      state.phaseType = '';
    },

    setFields: (state: DetailCustomizableStatus, action: PayloadAction<AnalysisDetailCustom[]>) => {
      state.fields = action.payload;
    },

    setIsLoading: (state: DetailCustomizableStatus, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },

    setShowDetailCustomizableModal: (state: DetailCustomizableStatus, action: PayloadAction<boolean>) => {
      state.showDetailCustomizableModal = action.payload;
    },
  },
});

export const {
  setDetailCustomizable,
  resetDetailCustomizable,
  setFields,
  setIsLoading,
  setShowDetailCustomizableModal,
} = detailCustomizableSlice.actions;

export const fetchDetailCustomizable = (props: { companyId: string; phaseType: string; }) => async (dispatch: Function) => {
  const { companyId, phaseType } = props;
  dispatch(setIsLoading(true));

  const params = {
    $limit: -1,
    companyId,
    phaseType,
  };

  try {
    const response = await axios.get<DetailCustomizablePayload[]>(ANALYSIS_DETAIL_CUSTOMIZABLE_URL, { params });
    if (response.data.length && response.data.length > 0) {
      dispatch(setDetailCustomizable(response.data[0]));
    }
  } catch (e) {
    console.log(e?.response);
  }

  dispatch(setIsLoading(false));
};

export const saveDetailCustomizable = (props: { companyId: string; phaseType: string; fields: string[] }) => async (dispatch: Function) => {
  const { companyId, phaseType, fields } = props;

  const body = {
    companyId,
    phaseType,
    fields,
  };

  try {
    const response = await axios.post(ANALYSIS_DETAIL_CUSTOMIZABLE_URL, body);
    dispatch(setDetailCustomizable(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export const updateDetailCustomizable = (props: { _id: string; fields: string[] }) => async (dispatch: Function) => {
  const { _id, fields } = props;

  const url = `${ANALYSIS_DETAIL_CUSTOMIZABLE_URL}/${_id}`;
  const body = { _id, fields };

  try {
    const response = await axios.patch(url, body);
    dispatch(setDetailCustomizable(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export default detailCustomizableSlice.reducer;
