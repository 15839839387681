import cx from 'classnames';
import { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { THEME } from '../../../config/commons';
import { Store } from '../../../state/store.interfaces';
import { getDaysToInitialStage } from '../../../helpers/analysis.helpers';

import { getDataDetail } from './helper';
import styles from './DataDetail.module.scss';
import DataItemDetail from './DataItemDetail';

interface Props {
  theme?: 'dark' | 'light';
}

export default function DataDetailCustom (props: Props) {
  const { theme } = props;

  const { company } = useSelector((state: Store) => state.header);
  const { analysis: analysisOriginal, user } = useSelector((state: Store) => state.detailAnalysis);
  const { fields } = useSelector((state: Store) => state.detailCustomizable);

  const isLightTheme = theme === THEME.LIGHT;
  const daysToInitialStage = getDaysToInitialStage({ company, daysToInitialStage: analysisOriginal.sowingId.daysToInitialStage });

  const renderItem = (props: { value: string | number | ReactNode; label: string }) => {
    const { value, label } = props;

    return (
      <DataItemDetail value={value} label={label} theme={theme} />
    );
  };

  const renderDataDetail = () => {
    const dataList = getDataDetail({ analysis: analysisOriginal, fields, user, daysToInitialStage });

    const numberOfRows = 4;
    const numberOfColumns = 3;

    const matrix = [];
    for (let i = 0; i < numberOfRows; i++) {
      const row = [];
      for (let j = 0; j < numberOfColumns; j++) {
        const dataIndex = (i * numberOfColumns) + j;
        const element = dataIndex < dataList.length ? dataList[dataIndex] : null;
        row.push(element);
      }
      matrix.push(row);
    }

    return (
      <div className={cx(styles.gridContainer, isLightTheme ? styles.gridContainerLight : styles.gridContainerDark)}>
        {matrix.map((row, rowIndex) => (
          <div key={rowIndex} className={cx(rowIndex % 2 === 0 ? isLightTheme ? styles.oddLight : styles.oddDark : '', rowIndex === 0 ? styles.firstRow : '')}>
            {row.map((item) => item ? renderItem({ label: item.label, value: item.value }) : null)}
          </div>
        ))}
      </div>
    );
  };

  return renderDataDetail();
}
