import i18next from 'i18next';

import { containerTypes, roundWeight, stockingPhaseTypes } from '../../../config/commons';
import { applyThousandsSeparator, charactersToSeparate } from '../../../utils/strings';
import { Analysis } from '../../Analysis/interfaces';
import { Stocking, Transfer } from '../interfaces';

export const getPhaseTypes = (phaseTypesSelected: string[]) => {
  let phaseTypes = '';

  for (let index = 0; index < phaseTypesSelected.length; index++) {
    const value = phaseTypesSelected[index];

    if (phaseTypes === '') {
      phaseTypes = value;
    } else {
      phaseTypes = phaseTypes + charactersToSeparate + value;
    }
  }

  return phaseTypes;
};

export const getStockingsIds = (listStockingsId: string[], enabledStockings: boolean[]) => {
  let stockingsId = '';

  for (let index = 0; index < listStockingsId.length; index++) {
    const value = listStockingsId[index];

    if (!enabledStockings[index]) {
      continue;
    }

    if (stockingsId === '') {
      stockingsId = value;
    } else {
      stockingsId = stockingsId + charactersToSeparate + value;
    }
  }

  return stockingsId;
};

export const getStockingsName = (listStockingsName: string[], enabledStockings: boolean[]) => {
  let stockingsName = '';

  for (let index = 0; index < listStockingsName.length; index++) {
    const value = listStockingsName[index];

    if (!enabledStockings[index]) {
      continue;
    }

    if (stockingsName === '') {
      stockingsName = value;
    } else {
      stockingsName = stockingsName + charactersToSeparate + value;
    }
  }

  return stockingsName;
};

export const getStockingVolume = (stocking: Stocking) => {
  const { tankId, cubicMeters, litersNumber, hectares } = stocking;
  switch (tankId.type) {
    case containerTypes.TANK:
      return `${applyThousandsSeparator(litersNumber)} L`;
    case containerTypes.POOL:
    case containerTypes.PRE_HATCHERY:
      return `${hectares} ha`;
    case containerTypes.RACEWAY:
    default:
      return `${cubicMeters} m³`;
  }
};

export const getTransferData = (transfers: Transfer[], stockingId: string) => {
  return transfers.find(item => item.stockingId === stockingId);
};

export const calcAvgGrowth = (analysis: Analysis, phaseType: string) => {
  switch (phaseType) {
    case stockingPhaseTypes.JUVENILE:
      return `${roundWeight({ value: (analysis.resultData.averageWeight / 1000) / analysis.inputData.stage, showUnit: false })} ${i18next.t('analysis.resultData.gramPerDay')}`;
    case stockingPhaseTypes.ADULT:
      return `${roundWeight({ value: (analysis.resultData.averageWeight / 1000) / (analysis.inputData.stage / 7), showUnit: false })} ${i18next.t('analysis.resultData.gramPerWeek')}`;
    default:
      return '';
  }
};

export const getHarvestedWeight = (stocking: Stocking) => {
  switch (stocking.phaseType) {
    case stockingPhaseTypes.LARVAE:
      return `${stocking.larvaePerGram}`;

    case stockingPhaseTypes.JUVENILE:
    case stockingPhaseTypes.ADULT:
      return `${stocking.averageHarvestedWeight} g`;
    default:
      return '';
  }
};
