import cx from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { Store } from '../../../state/store.interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../../helpers/theme';
import { formatMonthDayYear } from '../../../utils/date';
import { applyThousandsSeparator } from '../../../utils/strings';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import { LrvModal } from '../../../common/components/LrvModal/LrvModal';
import DotSpinner from '../../../common/components/DotSpinner/DotSpinner';
import { LrvButton } from '../../../common/components/LrvButton/LrvButton';
import { roundWeight, stockingStatuses, stockingTypes, transferTypes, stockingPhaseTypes, roundTwoDecimals } from '../../../config/commons';
import { calcDensity, calcHarvestedDensity, fixStockingStartDate, getAnimals, getStartDateStocking, getUnitDensity } from '../../../helpers/stocking.helpers';

import styles from './StockingInformationModal.module.scss';
import { calcAvgGrowth, getHarvestedWeight, getStockingVolume } from './helpers';
import { setMaturationsInfo, setShowStockingInformationModal, setStockingInformation } from './stockingAnalysisSlice';

interface Props {
  theme?: 'dark' | 'light';
}

export default function StockingInformationModal (props: Props) {
  const { theme = getCurrentTheme() } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const {
    showStockingInformationModal,
    isLoadingStockingInformation,
    stockingInformation,
    lastAnalysis,
    maturationsInfo,
  } = useSelector((state: Store) => state.stockingAnalysis);

  const renderRow = (props: { value: React.ReactNode; label: string }) => {
    const { label, value } = props;

    return (
      <tr>
        <td>
          <LrvText className={styles.bold} theme={theme} text={label} />
        </td>
        <td>
          <LrvText theme={theme} text={value} />
        </td>
      </tr>
    );
  };

  const renderTags = (values: string[]) => {
    const tags: JSX.Element[] = [];
    values.forEach(value => {
      tags.push(
        <div className={styles.tags}>
          <LrvText theme={theme} text={value} />
        </div>
      );
    });

    return (
      <div className={styles.tagsContainer}>
        {tags}
      </div>
    );
  };

  const renderAnalysisCodeRow = () => {
    if (!lastAnalysis) {
      return;
    }
    
    return (
      <tr>
        <td>
          <LrvText className={styles.bold} theme={theme} text={t('production.stockingInfoModal.lastAnalysis')} />
        </td>
        <td>
          <Link to={`/production/analysis/${lastAnalysis?._id}`} target='_blank'>
            <LrvText className={styles.linkText} theme={theme} text={lastAnalysis?.code} />
          </Link>
        </td>
      </tr>
    );
  };

  const renderStockingTable = () => {
    if (!stockingInformation) {
      return null;
    }

    const { campusId, moduleId, tankId } = stockingInformation;
    const { maturationCodes, maturations } = maturationsInfo;
    const isLarvaeStocking = stockingInformation.phaseType === stockingPhaseTypes.LARVAE;
    const stockingStartDate = getStartDateStocking({ stocking: stockingInformation, phaseType: stockingInformation.phaseType });
    const stockingStartDateFixed = fixStockingStartDate(stockingStartDate);
    const stockingType = t(`stockings.stockingTypes.${stockingInformation.type || stockingTypes.DIRECT_STOCKING}`);
    const stockingVolume = getStockingVolume(stockingInformation);
    const stockingAnimals = getAnimals({ stocking: stockingInformation, phaseTypeSelected: stockingInformation.phaseType });
    const stockingDensity = `${applyThousandsSeparator(calcDensity(stockingInformation))} ${getUnitDensity(stockingInformation)}`;

    return (
      <table className={cx(styles.table, styles.stockingTable)}>
        <tbody className={styles.body}>
          <tr className={styles.tableSubtitle}>
            <td colSpan={2}>
              <LrvText className={styles.bold} theme={theme} text={t('production.stockingInfoModal.stocking')} />
            </td>
          </tr>
          {renderRow({ label: t('production.stockingInfoModal.unit'), value: campusId.name })}
          {renderRow({ label: t('production.stockingInfoModal.module'), value: moduleId.name })}
          {renderRow({ label: t(`campus.containerTypes.${stockingInformation.tankId.type}`), value: tankId.name })}
          {renderRow({ label: t('production.stockingInfoModal.maturations'), value: maturations.length > 0 ? renderTags(maturations) : '' })}
          {renderRow({ label: t('production.stockingInfoModal.maturationCodes'), value: maturationCodes.length > 0 ? renderTags(maturationCodes) : '' })}
          {!isLarvaeStocking && renderRow({ label: t('production.stockingInfoModal.laboratory'), value: stockingInformation.labName })}
          {renderRow({ label: t('production.stockingInfoModal.stockingName'), value: stockingInformation.name })}
          {renderRow({ label: t('production.stockingInfoModal.productionCycle'), value: stockingInformation.code })}
          {renderRow({ label: t('production.stockingInfoModal.stockingStartDate'), value: formatMonthDayYear(stockingStartDateFixed) })}
          {!isLarvaeStocking && renderRow({ label: t('production.stockingInfoModal.stockingType'), value: stockingType })}
          {/* {!isLarvaeStocking && renderRow({ label: t('production.stockingInfoModal.stockingWeight'), value: '' })} */}
          {renderRow({ label: t('production.stockingInfoModal.sownAnimals'), value: applyThousandsSeparator(stockingAnimals) })}
          {renderRow({ label: t(`production.stockingInfoModal.${isLarvaeStocking ? 'volume' : 'hectares'}`), value: stockingVolume })}
          {renderRow({ label: t('production.stockingInfoModal.stockingDensity'), value: stockingDensity })}
        </tbody>
      </table>
    );
  };

  const renderCurrentStateTable = () => {
    if (!lastAnalysis || !stockingInformation) {
      return null;
    }

    const isLarvaeStocking = stockingInformation.phaseType === stockingPhaseTypes.LARVAE;
    const currentWeight = roundWeight({ value: lastAnalysis.resultData.averageWeight, showUnit: true });
    const avgGrowth = lastAnalysis && calcAvgGrowth(lastAnalysis, stockingInformation.phaseType);

    return (
      <table className={cx(styles.table, styles.currentStateTable)}>
        <tbody className={styles.body}>
          <tr className={styles.tableSubtitle}>
            <td colSpan={2}>
              <LrvText className={styles.bold} theme={theme} text={t('production.stockingInfoModal.currentState')} />
            </td>
          </tr>

          <tr className={styles.tableInfo}>
            <td colSpan={2}>
              <LrvText theme={theme} text={t('production.stockingInfoModal.lastAnalysisInfo')} />
            </td>
          </tr>

          {renderAnalysisCodeRow()}
          {renderRow({ label: t('production.stockingInfoModal.analysisDate'), value: formatMonthDayYear(lastAnalysis.createdAt) })}
          {renderRow({ label: t(`production.stockingInfoModal.${isLarvaeStocking ? 'stage' : 'productionDays'}`), value: lastAnalysis.inputData.stage })}
          {isLarvaeStocking && renderRow({ label: t('production.stockingInfoModal.currentPlg'), value: lastAnalysis.resultData.larvaePerGram })}
          {!isLarvaeStocking && renderRow({ label: t('production.stockingInfoModal.currentWeight'), value: currentWeight })}
          {renderRow({ label: t('production.stockingInfoModal.weightUniformity'), value: `${roundTwoDecimals(lastAnalysis.resultData.uniformity)} %` })}
          {isLarvaeStocking && renderRow({ label: t('production.stockingInfoModal.lengthUniformity'), value: `${roundTwoDecimals(100 - lastAnalysis.resultData.variationCoefficientLength)} %` })}
          {!isLarvaeStocking && renderRow({ label: t('production.stockingInfoModal.avgGrowth'), value: avgGrowth })}
        </tbody>
      </table>
    );
  };

  const renderHarvestTable = () => {
    if (!stockingInformation) {
      return null;
    }

    const isLarvaeStocking = stockingInformation.phaseType === stockingPhaseTypes.LARVAE;
    const isGrowOutStocking = stockingInformation.phaseType === stockingPhaseTypes.ADULT;
    const harvestedDensity = `${applyThousandsSeparator(calcHarvestedDensity(stockingInformation))} ${getUnitDensity(stockingInformation)}`;
    const gramPerWeek = lastAnalysis && `${roundWeight({ value: lastAnalysis.resultData.averageWeight / (lastAnalysis.inputData.stage / 7), showUnit: false })} ${t('analysis.resultData.gramPerWeek')}`;
    const harvestWeight = getHarvestedWeight(stockingInformation);
    const survivalRate = stockingInformation.survivalRate ? applyThousandsSeparator(roundTwoDecimals(stockingInformation.survivalRate)) : '';

    return (
      <table className={cx(styles.table, styles.harvestTable)}>
        <tbody className={styles.body}>
          <tr className={styles.tableSubtitle}>
            <td colSpan={2}>
              <LrvText className={styles.bold} theme={theme} text={t('production.stockingInfoModal.harvest')} />
            </td>
          </tr>

          {renderAnalysisCodeRow()}
          {renderRow({ label: t('production.stockingInfoModal.harvestDate'), value: formatMonthDayYear(stockingInformation.endDate) })}
          {isGrowOutStocking && renderRow({ label: t('production.stockingInfoModal.poundsHarvested'), value: applyThousandsSeparator(stockingInformation.poundsHarvested) })}
          {renderRow({ label: t('production.stockingInfoModal.animalsHarvested'), value: applyThousandsSeparator(stockingInformation.harvestQuantity) })}
          {renderRow({ label: t('production.stockingInfoModal.survival'), value: survivalRate })}
          {renderRow({ label: t('production.stockingInfoModal.harvestDensity'), value: harvestedDensity })}
          {renderRow({ label: t(`production.stockingInfoModal.${isLarvaeStocking ? 'harvestPlg' : 'harvestWeight'}`), value: harvestWeight })}
          {lastAnalysis && renderRow({ label: t(`production.stockingInfoModal.${isLarvaeStocking ? 'harvestStage' : 'productionDays'}`), value: lastAnalysis.inputData.stage })}
          {(lastAnalysis && !isLarvaeStocking) && renderRow({ label: t('production.stockingInfoModal.avgGrowth'), value: gramPerWeek })}
        </tbody>
      </table>
    );
  };

  const renderBodyModal = () => {
    if (isLoadingStockingInformation) {
      return (
        <div className={styles.spinner}>
          <DotSpinner theme='light' />
        </div>
      );
    }

    const isActiveStocking = stockingInformation && (stockingInformation.status === stockingStatuses.ACTIVE || stockingInformation.status === transferTypes.PARTIAL_TRANSFER);

    return (
      <div className={styles.modalBody}>
        <table className={styles.table}>
          <tbody className={styles.body}>
            <tr className={styles.tableSubtitle}>
              <td colSpan={4}>
                <LrvText className={styles.bold} theme={theme} text={t(`production.stockingInfoModal.${isActiveStocking ? 'activeStocking' : 'stockingFinished'}`).toUpperCase()} />
              </td>
            </tr>
          </tbody>
        </table>

        {renderStockingTable()}
        {isActiveStocking ? renderCurrentStateTable() : renderHarvestTable()}
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <LrvButton
          type='primary'
          onClick={() => dispatch(setShowStockingInformationModal(false))}
        >
          {t('analysis.accept')}
        </LrvButton>
      </div>
    );
  };

  return (
    <LrvModal
      theme={theme}
      title={t('production.filters.stockingInfo')}
      open={showStockingInformationModal}
      destroyOnClose={true}
      closeIcon={<Icon id='close_icon' name='close' />}
      onCancel={() => {
        dispatch(setShowStockingInformationModal(false));
        dispatch(setStockingInformation(undefined));
        dispatch(setMaturationsInfo({ maturationCodes: [], maturations: [] }));
      }}
      footer={[renderFooter()]}
      wrapClassName={styles.wrapModal}
    >
      {renderBodyModal()}
    </LrvModal >
  );
}