import i18next from 'i18next';
import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { User } from '../Users/interfaces';
import { getUserSession } from '../../utils/userSession';
import { UserSession } from '../../common/interfaces/auth';
import { axiosClient as axios } from '../../utils/axios_instance';
import { paginationHistoryManagement, passwordError } from '../../config/commons';
import { openSuccessNotification, openErrorNotification } from '../../common/notification/Notification';
import { USERS_URL, PASSWORD_RESET, LOG_BALANCES_URL, API_TOKEN_CUSTOM_URL, WEB_HOOK_URL, COMPANIES_URL, COUPONS_URL, INTEGRATION_API_KEY_URL } from '../../config/config.api';

import { initialHistoryManagementFilters } from './helpers';
import { APIToken, HistoryManagementFilter, HistoryManagementPayload, ProfileState } from './interfaces';

const initialState: ProfileState = {
  _id: '',
  firstName: '',
  lastName: '',
  email: '',
  defaultPhase: '',
  language: '',
  active: true,
  isLoading: false,
  historyManagement: {
    isLoading: false,
    total: 0,
    currentPage: 1,
    data: [],
    users: [],
    filters: initialHistoryManagementFilters,
  },
  imageAPI: {
    _id: '',
    companyId: '',
    apiKey: '',
    url: '',
    token: '',
  },
  dataAPI: {
    _id: '',
    companyId: '',
    apiKey: '',
    url: '',
    token: '',
  },
  allowDataApi: false,
  allowImageApi: false,
};

export const userSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setSelectedUser: (state: ProfileState, action: PayloadAction<ProfileState>) => {
      state._id = action.payload._id;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.language = action.payload.language;
      state.active = action.payload.active;
      state.email = action.payload.email;
      state.theme = action.payload.theme;
      state.defaultPhase = action.payload.defaultPhase;
    },
    setTheme: (state: ProfileState, action: PayloadAction<'dark' | 'light' | undefined>) => {
      state.theme = action.payload;
    },
    setIsUserLoading: (state: ProfileState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setHistoryManagement: (state: ProfileState, action: PayloadAction<HistoryManagementPayload>) => {
      state.historyManagement.total = action.payload.total;
      state.historyManagement.data = action.payload.data;
    },
    setIsLoadingHistory: (state: ProfileState, action: PayloadAction<boolean>) => {
      state.historyManagement.isLoading = action.payload;
    },
    setCurrentPageHistory: (state: ProfileState, action: PayloadAction<number>) => {
      state.historyManagement.currentPage = action.payload;
    },
    setUsers: (state: ProfileState, action: PayloadAction<User[]>) => {
      state.historyManagement.users = action.payload;
    },
    setHistoryManagementFilters: (state: ProfileState, action: PayloadAction<HistoryManagementFilter>) => {
      state.historyManagement.filters = action.payload;
    },
    resetHistoryManagementFilters: (state: ProfileState) => {
      state.historyManagement.currentPage = 1;
      state.historyManagement.filters = initialHistoryManagementFilters;
    },

    setImageApi: (state: ProfileState, action: PayloadAction<APIToken>) => {
      state.imageAPI = action.payload;
    },
    setDataApi: (state: ProfileState, action: PayloadAction<APIToken>) => {
      state.dataAPI = action.payload;
    },
    setAllowImageApi: (state: ProfileState, action: PayloadAction<boolean>) => {
      state.allowImageApi = action.payload;
    },
    setAllowDataApi: (state: ProfileState, action: PayloadAction<boolean>) => {
      state.allowDataApi = action.payload;
    },
  },
});

export const {
  setSelectedUser,
  setTheme,
  setIsUserLoading,

  setHistoryManagement,
  setIsLoadingHistory,
  setCurrentPageHistory,
  setUsers,
  setHistoryManagementFilters,
  resetHistoryManagementFilters,

  setImageApi,
  setDataApi,
  setAllowImageApi,
  setAllowDataApi,
} = userSlice.actions;

export const fetchUserProfile = () => async (dispatch: Function) => {
  dispatch(setIsUserLoading(true));

  try {
    const userSession: UserSession = getUserSession();
    const paramsUser = {
      $select: ['email', 'firstName', 'lastName', 'active', 'language', 'theme', 'defaultPhase'],
      '$sort[firstName]': 1,
      active: true
    };

    const response = await axios.get<ProfileState>(`${USERS_URL}/${userSession._id}`, { params: paramsUser });
    dispatch(setSelectedUser(response.data));
    dispatch(setIsUserLoading(false));
  } catch (e) {
    console.log(e?.response);
  }
};

export const updateProfile = async (profile: { firstName: string; lastName: string; language: string; defaultPhase: string; companyId?: string }) => {
  const userSession = getUserSession();
  profile.companyId = userSession.companyId;

  try {
    const response = await axios.patch<ProfileState>(`${USERS_URL}/${userSession._id}`, profile);
    localStorage.setItem('user', JSON.stringify(response.data));
    openSuccessNotification(i18next.t('users.userUpdated'));
  } catch (e) {
    console.log(e?.response);
  }
};

export const updateTheme = (props: { theme: string }) => async (dispatch: Function) => {
  const { theme } = props;

  const userSession: UserSession = getUserSession();

  const params = {
    theme,
    companyId: userSession.companyId,
  };

  try {
    const response = await axios.patch(`${USERS_URL}/${userSession._id}`, params);
    localStorage.setItem('user', JSON.stringify(response.data));
    dispatch(setSelectedUser(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export const catchPasswordErrors = (e: AxiosError) => {
  const responseData = e?.response?.data;

  if (responseData?.message && responseData?.message.statusCode === 401) {
    openErrorNotification(i18next.t('password.reset.tokenInvalid'));
    return;
  }

  const error = responseData?.data?.error;
  switch (error) {
    case passwordError.SAME_PASSWORD:
      openErrorNotification(i18next.t('users.profile.samePassword'));
      break;

    case passwordError.SAME_RECENT_PASSWORDS:
      openErrorNotification(i18next.t('users.profile.sameRecentPasswords'));
      break;

    case passwordError.PASSWORD_POLICY:
      openErrorNotification(i18next.t('users.profile.passwordPolicy'));
      break;

    case passwordError.CURRENT_PASSWORD:
      openErrorNotification(i18next.t('users.profile.currentPassword'));
      break;

    case passwordError.WHITE_SPACES:
      openErrorNotification(i18next.t('users.profile.whiteSpaces'));
      break;

    default:
      openErrorNotification(i18next.t('password.reset.errorText'));
      break;
  }
};

export const updatePassword = async (password: { currentPassword: string; newPassword: string }) => {
  try {
    await axios.patch(PASSWORD_RESET, password);
  } catch (e) {
    console.log(e?.response);
    catchPasswordErrors(e);
    return;
  }
  openSuccessNotification(i18next.t('users.passwordUpdated'));
};

export const fetchHistoryManagement = (params: { page: number; origin?: string; userId?: string }) => async (dispatch: Function) => {
  const { page, origin, userId } = params;
  let skip = 0;

  if (page !== 0) {
    skip = paginationHistoryManagement * (page - 1);
  }

  dispatch(setIsLoadingHistory(true));

  const userSession = getUserSession();
  const paramsLogBalance = {
    $limit: paginationHistoryManagement.toString(),
    $skip: skip.toString(),
    companyId: userSession.companyId,
    origin: origin || undefined,
    userId: userId || undefined,
  };

  try {
    const response = await axios.get<HistoryManagementPayload>(LOG_BALANCES_URL, { params: paramsLogBalance });
    dispatch(setHistoryManagement(response.data));
    dispatch(setIsLoadingHistory(false));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchUsers = () => async (dispatch: Function) => {
  const userSession = getUserSession();

  const params = {
    $limit: '-1',
    companyId: userSession.companyId,
    $select: ['_id', 'firstName', 'lastName'],
    '$sort[firstName]': 1,
  };

  try {
    const response = await axios.get<User[]>(USERS_URL, { params });
    dispatch(setUsers(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchAllowApiService = () => async (dispatch: Function) => {
  const userSession = getUserSession();
  const params = {
    $select: ['allowDataApi', 'allowImageApi']
  };

  try {
    const response = await axios.get(`${COMPANIES_URL}/${userSession.companyId}`, { params });
    dispatch(setAllowImageApi(response.data.allowImageApi));
    dispatch(setAllowDataApi(response.data.allowDataApi));
  } catch (e) {
    console.log(e?.response);
  }
};

export const getImageApi = () => async (dispatch: Function) => {
  try {
    const response = await axios.get<APIToken>(API_TOKEN_CUSTOM_URL);
    dispatch(setImageApi(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export const updateImageApiKey = () => async (dispatch: Function) => {
  try {
    const response = await axios.patch<APIToken>(API_TOKEN_CUSTOM_URL);
    dispatch(setImageApi(response.data));
    openSuccessNotification(i18next.t('apiService.success'));
  } catch (e) {
    console.log(e?.response);
  }
};

export const updateWebHook = (params: { webHook: string }) => async (dispatch: Function) => {
  try {
    const response = await axios.patch<APIToken>(WEB_HOOK_URL, params);
    dispatch(setImageApi(response.data));
    openSuccessNotification(i18next.t('apiService.success'));
  } catch (e) {
    console.log(e?.response);
  }
};

export const createDataApiKey = () => async (dispatch: Function) => {
  try {
    const response = await axios.post<APIToken>(INTEGRATION_API_KEY_URL);
    dispatch(setDataApi(response.data));
    openSuccessNotification(i18next.t('apiService.success'));
  } catch (e) {
    console.log(e?.response);
  }
};

export const updateDataApiKey = () => async (dispatch: Function) => {
  try {
    const response = await axios.patch<APIToken>(INTEGRATION_API_KEY_URL);
    dispatch(setDataApi(response.data));
    openSuccessNotification(i18next.t('apiService.success'));
  } catch (e) {
    console.log(e?.response);
  }
};

export const getDataApi = () => async (dispatch: Function) => {
  try {
    const response = await axios.get<APIToken>(INTEGRATION_API_KEY_URL);
    dispatch(setDataApi(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};


export const createCoupon = async (body: { email: string; quota: number }) => {
  try {
    await axios.post(COUPONS_URL, body);
    openSuccessNotification(i18next.t('coupon.success'));
  } catch (e) {
    console.log(e?.response);
  }
};

export default userSlice.reducer;
