import moment from 'moment';

import { Banner } from '../Settings/interfaces';
import { companiesTypes, getMainRole, roles } from '../../config/commons';
import { getUserSession } from '../../utils/userSession';

export const showDropdownCompany = () => {
  let pathname = window.location.pathname;
  pathname = pathname.replace(/\/$/, '');
  const higherRole = getMainRole();

  switch (higherRole) {
    case roles.SUPER_ADMIN:
    case roles.PARTIAL_ADMIN:
      return pathname === '/production/stockings' || pathname === '/production/insights' || pathname === '/production/parameters' || pathname === '/reports/current-state' || pathname === '/reports/performance' || pathname === '/reports/quadrant' || pathname === '/reports/growth-delta' || pathname === '/reports/survival-rate' || pathname === '/reports/laboratory' || pathname === '/genetics/insights' || pathname === '/genetics/setup' || pathname === '/dashboard' || pathname === '/users' || pathname.includes('/units') || pathname === '/analysis-without-stockings' || pathname === '/archive' || pathname === '/payments' || pathname === '/reference-curves';

    case roles.SALES_MANAGER:
    case roles.SALES:
      return pathname === '/dashboard' || pathname === '/genetics/setup' || pathname === '/users' || pathname.includes('/units') || pathname === '/payments' || pathname === '/reference-curves';

    case roles.BUSINESS_MANAGER:
      return pathname === '/dashboard' || pathname === '/genetics/setup' || pathname === '/users' || pathname.includes('/units') || pathname === '/reference-curves';

    default:
      return false;
  }
};

export const showDropdownPhaseType = () => {
  let pathname = window.location.pathname;
  pathname = pathname.replace(/\/$/, '');

  return pathname === '/production/stockings' || pathname === '/production/insights' || pathname === '/production/parameters' || pathname === '/reports/current-state' || pathname === '/reports/quadrant' || pathname === '/reports/growth-delta' || pathname === '/reports/survival-rate' || pathname === '/reports/laboratory' || pathname === '/genetics/insights' || pathname === '/dashboard' || pathname === '/analysis-without-stockings' || pathname === '/archive' || pathname === '/units';
};

export const showAlertByCompany = (alertMessage: Banner) => {
  if (!alertMessage?.companyIds || !alertMessage?.companyIds.length) {
    return false;
  }
  const userSession = getUserSession();

  return (
    alertMessage.companyIds.some((companyId: string) => companyId == companiesTypes.ALL) ||
    alertMessage.companyIds.some((companyId: string) => companyId === userSession.companyId)
  );
};

export const showAlertByDate = (alertMessage: Banner) => {
  if (!alertMessage?.endDate) {
    return true;
  }

  const currentDate = moment().startOf('day');
  const endDate = moment(alertMessage?.endDate).startOf('day');
  return currentDate.isSameOrBefore(endDate);
};
