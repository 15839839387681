import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { Store } from '../../../state/store.interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import { LrvModal } from '../../../common/components/LrvModal/LrvModal';
import DotSpinner from '../../../common/components/DotSpinner/DotSpinner';
import { LrvButton } from '../../../common/components/LrvButton/LrvButton';

import { AnalysisDetailCustom } from './interfaces';
import styles from './ExtraInformationModal.module.scss';
import { setShowExtraInformationModal } from './detailAnalysisSlice';
import { getKeysAnalysisCustom, getLabelForKey, getValueForKey } from './helper';

interface Props {
  theme?: 'dark' | 'light';
}

export default function ExtraInformationModal (props: Props) {
  const { theme = 'dark' } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const {
    showExtraInformationModal,
    analysis,
    user,
    isLoadingExtraInformation,
  } = useSelector((state: Store) => state.detailAnalysis);
  const { company } = useSelector((state: Store) => state.header);
  const { fields } = useSelector((state: Store) => state.detailCustomizable);

  const [allData, setAllData] = useState<{ keysSelected: AnalysisDetailCustom[], otherKeys: AnalysisDetailCustom[] }>({ keysSelected: [], otherKeys: [] });

  const analysisPhaseType = analysis.phaseType;

  useEffect(() => {
    const { keysSelected, otherKeys } = getKeysAnalysisCustom({ phaseType: analysisPhaseType, fields });
    setAllData({ keysSelected, otherKeys });
  }, [analysisPhaseType, fields]);

  const renderRow = (props: { value: React.ReactNode; label: string }) => {
    const { label, value } = props;

    return (
      <tr>
        <td>
          <LrvText className={styles.bold} theme={theme} text={label} />
        </td>
        <td>
          <LrvText theme={theme} text={value} />
        </td>
      </tr>
    );
  };

  const renderBody = () => {
    return (
      <tbody className={styles.body}>
        {allData.otherKeys.map((item) => {
          return renderRow({ label: getLabelForKey({ analysis, field: item }), value: getValueForKey({ analysis, field: item, user, daysToInitialStage: company.daysToInitialStage }) });
        })}
      </tbody>
    );
  };

  const renderTable = () => {
    return (
      <table className={styles.table}>
        {renderBody()}
      </table>
    );
  };

  const renderBodyModal = () => {
    if (isLoadingExtraInformation) {
      return (
        <div className={styles.spinner}>
          <DotSpinner theme='light' />
        </div>
      );
    }

    return renderTable();
  };

  const renderFooter = () => {
    return (
      <div>
        <LrvButton
          type='primary'
          onClick={() => dispatch(setShowExtraInformationModal(false))}
        >
          {t('analysis.accept')}
        </LrvButton>
      </div>
    );
  };

  return (
    <LrvModal
      theme={theme}
      title={t('detail.extraInformation.title')}
      open={showExtraInformationModal}
      destroyOnClose={true}
      closeIcon={<Icon id='close_icon' name='close' />}
      onCancel={() => {
        dispatch(setShowExtraInformationModal(false));
      }}
      footer={[renderFooter()]}
    >
      {renderBodyModal()}
    </LrvModal >
  );
}